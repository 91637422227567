<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title tx-dark tx-medium mg-b-10">All Services</h5>
      <!--            <p class="card-subtitle mg-b-15">&#45;&#45; Service definition here &#45;&#45;</p>-->
      <p class="card-subtitle mg-b-15"></p>

      <div class="table-responsive mg-t-25">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="wd-10p">&nbsp;&nbsp;#</th>
              <th>Name</th>
              <th>Description</th>
              <th>State</th>
              <th class="wd-20p">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-bind:key="index"
              v-for="(a, index) in services"
            >
              <td>&nbsp;&nbsp;{{page*per_page +index+1}}</td>
              <td><strong>{{a.name | capitalize}}</strong></td>
              <td>{{a.description | truncate(50, '...')}}</td>
              <td v-if="a.status === 'ACTIVE'"><strong>Active&nbsp;<i class="fas fa-check-circle"></i></strong></td>
              <td v-if="a.status === 'INACTIVE'"><strong>Inactive&nbsp;<i class="fas fa-circle"></i></strong></td>

              <td>
                <button
                  :disabled="a.status === 'INACTIVE'"
                  @click="unsubscribe(a.entity_id)"
                  class="btn btn-danger btn-rounded btn-sm"
                  type="button"
                  v-if="a.is_subscribe"
                >
                  Unsubscribe
                </button>
                <button
                  :disabled="a.status === 'INACTIVE'"
                  @click="subscribe((a.entity_id))"
                  class="btn btn-success btn-rounded btn-sm"
                  type="button"
                  v-else
                >
                  Subscribe
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- table-responsive -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Services",
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      only_services: [],
      services: [],
      org_services: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getServices();
      await this.getOrgService();
      await this.arrangeService();
    },
    async getServices() {
      try {
        let reply = await this.$accounts.get(`/accounts/services`);
        this.only_services = reply.data ? reply.data.services : [];
      } catch (e) {

      }
    },
    async getOrgService() {
      try {
        let reply = await this.$accounts.get(`/accounts/org/services`);
        this.org_services = reply.data ? reply.data.services : [];
      } catch (e) {
        console.log(e);
      }
    },
    async arrangeService() {
      this.services = [];
      _.forEach(this.only_services, (service) => {
        if (_.some(this.org_services, { entity_id: service.entity_id })) {
          service['is_subscribe'] = true;
          this.services.push(service);
        } else {
          this.services.push(service);
        }
      });

    },
    async subscribe(serviceid) {
      try {
        await this.$accounts.post(`/accounts/services/${serviceid}/subscribe`);
        this.$toastr.s('Service Successfully Subscribed', 'Success');
        this.$root.$emit('refreshService');
        this.init();
      } catch (e) {

      }
    },
    async unsubscribe(serviceid) {
      try {
        await this.$accounts.post(`/accounts/services/${serviceid}/unsubscribe`);
        this.$toastr.s('Service Successfully Unsubscribed', 'Success');
        this.$root.$emit('refreshService');
        this.init();
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped>
</style>